export const countryList = [
  { id: 1, dialCode: '+91', value: 'ind', name: '🇮🇳' },
  { id: 2, dialCode: '+1', value: 'usa', name: '🇺🇸' },
  //{ id: 3, dialCode: '+1', value: 'can', name: 'CA' },
  { id: 4, dialCode: '+44', value: 'gbr', name: 'GB' },
];

export const countryCurrency: any = {
  usa: 'USD',
  ind: 'INR',
  can: 'CAD',
  gbr: 'GBP',
};

export const countryStartingPriceMapping: any = {
  usa: {
    startingPrice: '5.99',
    currencySymbol: '$',
    countryName: 'United States',
  },
  ind: { startingPrice: '149', currencySymbol: '₹', countryName: 'India' },
  can: { startingPrice: '6.99', currencySymbol: '$', countryName: 'Canada' },
  gbr: {
    startingPrice: '3.99',
    currencySymbol: '£',
    countryName: 'United Kingdom',
  },
};
